import React, { Component } from 'react'

import PageContact from '../components/page-contact'
import PageCtaBoxes from '../components/page-cta-boxes'
import SEO from '../components/seo'

class Page extends Component {
  render() {
    return (
      <>
        <SEO title="Thanks" />
        <PageContact submitted={true} />
        {/* <PageCtaBoxes /> */}
      </>
    )
  }
}

export default Page
